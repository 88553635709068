<template>
  <div class="divBox">
    <el-card class="box-card">
      <div>
        <el-tabs :tab-position="'left'" @tab-click="tabClick" v-model="activeName">
          <el-tab-pane v-for="(item,index) in tabList" :key="index" :name="''+item.type" :label="item.title">
            <div class="font" style="display:flex; align-items: center;margin-bottom: 20px;">
                <div class="shu"></div> {{ item.title }}
            </div>
            <FormEditor :key="index" :details="form.details" @editoChange="editoChange" :disabled="false"/>
          </el-tab-pane>
        </el-tabs>
        <div class="btn">
          <el-button class="button" type="primary" @click="submenus"
            >提交</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  userAgreementList,
  userAgreementDetail,
  userAgreementUpdate,
} from "@/api/system/config";
import FormEditor from "@/components/formEditor/index"
export default {
  name: "agreements",
  components:{
    FormEditor
  },
  data() {
    return {
      tabList: [],
      formValidate: {
        agree: "",
      },
      editableTabsValue: "",
      form:{},
      activeName:""
    };
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      userAgreementList({pageNum:1,pageSize:100})
        .then((res) => {
          this.tabList = res.data.records;
          this.activeName = res.data.records[0].type.toString()
          this.getDetail()
        })
        .catch((res) => {

        });
    },
    getDetail() {
      userAgreementDetail(this.activeName)
        .then((res) => {
          this.form.details = res.data.content
          this.$forceUpdate()
        })
        .catch((res) => {

        });
    },
    editoChange(e){
      this.form.details = e
    },
    tabClick(e){
      this.getDetail()
    },
    submenus() {
      console.log('--------111',this.form.details)
      if (this.form.details == "" || !this.form.details) {
        this.$message.warning("请输入协议信息！");
        return;
      } else {
        let obj = this.tabList.filter((item)=>item.type==this.activeName)
        userAgreementUpdate({...obj[0],content:this.form.details})
          .then(async (res) => {
            console.log('123',res)
            this.$message.success(res.msg);
          })
      }
    }
  },
};
</script>
<style scoped lang="scss">
.box-card /deep/.el-card__body {
  padding-left: 0px;
}
/deep/.el-tabs__item.is-active {
  color: var(--prev-color-primary);
  background-color: #ccc;
  background: #437efd1e;
  // opacity: 0.1;
}
/deep/.el-tabs--left .el-tabs__header.is-left {
  float: left;
  margin-bottom: 0;
  margin-right: 20px;
  height: 700px;
  width: 170px;
  font-size: 13px;
}
.gang {
  border: 2px solid var(--prev-color-primary);
  margin-right: 10px;
}
.btn {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  .button {
    display: block;
    margin: 17px auto 0px;
  }
}
.content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  img {
    max-width: 100%;
  }
  .ueditor {
    flex: 1;
    .font {
      font-size: 20px;
      font-weight: 600;
      color: #303133;
      margin-bottom: 30px;
    }
  }
}
.shu{
  width: 4px;
  height: 18px;
  background-color: #4073FA;
  margin-right: 5px;
}
</style>